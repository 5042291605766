import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Layout from '../components/Layout'

export default function userNeedsAndValidationTestsTemplate({
  data,
  pageContext,
}) {
  const { frontmatter } = data.markdownRemark
  const { filePath } = pageContext
  const filePathParts = filePath.split('/')
  const documentName = filePathParts[2]
  return (
    <Layout title={documentName} filePath={filePath}>
      <p>Product Description: {frontmatter.productDescription}</p>
      <table className="table">
        <thead>
          <tr>
            <th className="tg-bobw" rowspan="2">
              ID
            </th>
            <th className="tg-bobw" rowspan="2">
              User Needs
            </th>
            <th className="tg-bobw" colspan="4">
              Validation Test
            </th>
            <th className="tg-bobw" colspan="4">
              Results Documentation
            </th>
          </tr>
          <tr>
            <td className="tg-bobw">Document&nbsp;&nbsp;&nbsp;ID</td>
            <td className="tg-bobw">Section</td>
            <td className="tg-bobw">Revision</td>
            <td className="tg-bobw">Document Description</td>
            <td className="tg-bobw">Document ID</td>
            <td className="tg-bobw">Section</td>
            <td className="tg-bobw">Revision</td>
            <td className="tg-bobw">Document Description</td>
          </tr>
        </thead>
        {frontmatter.testTable.map((row, i) => (
          <tr>
            <td>{i}</td>
            <td>{row.userNeeds}</td>
            <td>{row.validationTestSection}</td>
            <td>{row.section}</td>
            <td>{row.revision}</td>
            <td>
              <Link to={row.documentDescription}>
                {row.documentDescription}
              </Link>
            </td>
            <td>{row.resultsDocumentationSection}</td>
            <td>{row.resultsSection}</td>
            <td>{row.resultsRevision}</td>
            <td>
              <Link to={row.resultsDocumentationDocumentDescription}>
                {row.resultsDocumentationDocumentDescription}
              </Link>
            </td>
          </tr>
        ))}
      </table>
    </Layout>
  )
}

export const query = graphql`
  query MarkdownQueryUserNeedsAndValidationTestsTemplate($filePath: String) {
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      frontmatter {
        templateName
        productDescription
        testTable {
          userNeeds
          validationTestSection
          section
          documentDescription
          resultsDocumentationSection
          resultsSection
          resultsDocumentationDocumentDescription
        }
      }
    }
  }
`
